.bloc-strategy {
  .bloc {
    width: 100%;
    margin-bottom: 2rem;

    &.mb-0 {
      @media (min-width: $md) {
        margin-bottom: 0;
      }
    }

    @media (min-width: $md) {
      width: 45%;
      margin-bottom: 4rem;
    }

    h3, p, ul {
      margin: 0;
    }

    ul {
      padding: 0 0 0 1.5rem;
    }

    h3 {
      font-size: 2rem;

      @media (min-width: $md) {
        font-size: 2.6rem;
        margin-bottom: 0.6rem;
      }
    }

    p {
      font-size: 1.4rem;

      @media (min-width: $md) {
        font-size: 1.8rem;
      }
    }
  }
}

.card {
  width: 100%;
  text-decoration: none;
  margin-bottom: 4rem;
  display: block;

  @media (min-width: $md) {
    width: 48%;
    margin-bottom: 0;
  }

  &:hover {
    opacity: 0.75;
  }

  h3, p {
    margin: 0;
    color: $body-color;
  }

  h3 {
    font-size: 2.2rem;
    margin-bottom: 0.6rem;
    line-height: 1.3;

    @media (min-width: $md) {
      margin-top: 1rem;
    }
  }

  p {
    font-size: 1.6rem;
    color: #757575;
  }
}

.methods {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: $md) {
    flex-wrap: nowrap;
  }

  li {
    line-height: 1.2;
    font-size: 1.4rem;
    text-align: center;
    flex: 1 1 auto;
    width: 50%;
    padding: 0 1rem;

    img {
      width: 4rem;
    }

    @media (min-width: $md) {
      flex: 1 1 0;
      width: auto;
      padding: 0;

      img {
        width: auto;
      }

      &:first-child {
        img {
          width: 7.5rem;
          margin: 1.7rem 0;
        }
      }
    }

    h3 {
      min-height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
      margin: 0.8rem 0;

      @media (min-width: $md) {
        font-size: 1.6rem;
        margin: 1.4rem 0;
      }
    }

    p {
      font-size: 1.2rem;
      @media (min-width: $md) {
        font-size: 1.4rem;
      }
    }
  }
}

.logo {
  margin: 0;
  list-style: none;
  padding: 0;
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $md) {
    justify-content: space-around;
  }

  li {
    margin-right: 2%;
    width: 17%;

    @media (min-width: $md) {
      margin-right: 2%;
      width: 15%;
    }

    img {
      //filter: grayscale(1);
    }

    &:last-child {
      margin-right: 0;
    }

    &.square {
      width: 10%;
    }
  }
}