.container {
  max-width: 90rem;
  margin: 0 2rem;

  &.d-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &-max {
    max-width: 100rem;
    //margin: 0 2rem;
    @media (min-width: $md) {
      margin: auto;
    }
  }

  @media (min-width: $md) {
    margin: auto;
    padding: 0 4rem;
  }
}

.d-flex {
  display: flex;
  justify-content: space-between;

  &-md {
    @media (min-width: $md) {
      display: flex;
      justify-content: space-between;
    }
  }
}

.background {
  background-color: $bg-color;

  &-dark {
    background-color: #02221D;
  }

  &-black {
    background-color: #202022;

    p, h3, h4 {
      color: #E5E5ED;
    }
  }
}

img {
  max-width: 100%;
}

.img {
  &-sm {
    max-width: 50rem;
    margin: auto;
    display: block;
  }
}

.p-md {
  padding: 0.1rem;
}

.pb-md {
  padding-bottom: 3rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

figure {
  margin: 0;
  @media (min-width: $md) {
    margin: 1.6rem 4rem;
  }
}

figure img {
  width: 100%;
}

.figure-min {
  margin: auto;
  width: auto;
  display: block;
}

.figure-nomargin {
  margin: 1.6rem 0;
}

figcaption {
  text-align: center;
  color: #757575;
  font-size: 1.2rem;
  margin: 0 4rem;
  line-height: 1.2;
  margin-top: 2px;

  @media (min-width: $md) {
    font-size: 1.4rem;
  }
}

.box {
  border: 1px solid;
  padding: 12px;
  border-radius: 12px;
  margin-top: 2rem;
}

.box > p {
  margin-top: 0!important;
}

.box p:last-child {
  margin-bottom: 0!important;
}

.alert {
  margin: 2rem 0;
  border: 1px solid #000;
  padding: 1.6rem;
  border-radius: 0.8rem;
  
  ul {
    padding-left: 1.6rem;
    margin: 0;

    li {
      margin-bottom: 0.4rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .title {
    font-size: 1.6rem;
    margin: 0;
  }

  &-primary {
    color: #052c65;
    background-color: #cfe2ff;
    border-color: #9ec5fe;
  }
}