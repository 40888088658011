a.case-study {
  &:hover {
    opacity: 0.75;
  }
}
.case-study-v2 {
  max-width: 120rem;
  margin: 2rem auto;
  padding: 2rem 1.5rem;
  flex-direction: column;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  border-radius: 4px;

  &:hover {
    background-color: #F1F2F3;
    cursor: pointer;
    border-radius: 8px;
  }

  .case-study-bloc {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    grid-row-gap: 0.5rem;
    color: $body-color;

    .case-study-logos {
      display: flex;
      align-items: center;
    }
    .case-study-logo {
      width: 10rem;
      height: 100%;

      &-m {
        width: 14rem;
        height: 100%;
      }
    }
    .case-study-title  {
      margin: 0;
      padding-left: 1rem;
      font-size: 2.2rem;
    }
    .case-study-description {
      max-width: 86%;
      font-weight: 500;
    }
    .case-study-img-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (min-width: $md) {
        flex-direction: row;
      }

      img {
        width: 100%;
        height: auto;
        border: 1px solid lightgray;
        margin-bottom: 1rem;
        border-radius: 4px;

        @media (min-width: $md) {
          width: 33%;
          height: 100%;
          margin-bottom: 0;
        }
      }
    }
  }
}

.case-study {
  border: 0.3rem solid black;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $body-color;
  padding: 1rem;

  @media (min-width: $md) {
    border: 0.5rem solid black;
    padding: 0;
  }

  &-bloc {
    margin-right: 2rem;
    p {
      margin: 0!important;
    }

    &--alone {
      padding: 3rem;
    }
  }

  &-img {
    display: none;
    margin-right: 3rem;
    padding-top: 2rem;

    @media (min-width: $md) {
      height: 22rem;
      display: block;
    }
  }

  &.nrj {
    border-color: #EB6473;

    .case-study-img {
      margin-left: 9rem;
    }
    .case-study-logo {
      width: 8rem;
    }
  }

  &.ftv {
    border-color: #004D90;

    .case-study-logo {
      width: 15rem;
      position: relative;
      left: -1rem;

      @media (min-width: $md) {
        left: -1.8rem;
      }
    }

    .case-study-bloc {
      @media (min-width: $md) {
        position: relative;
        left: -2rem;
      }
    }
  }

  &.chef {
    border-color: #6B36EB;

    .case-study-logo {
      width: 13rem;
    }

    .case-study-bloc {
      position: relative;
      left: 1rem;
    }
  }

  //e
  &.e-ops {
    border-color: #002E5B;

    .case-study-logo {
      width: 16rem;
    }
    .case-study-img {
      margin-left: 6rem;
    }
  }

  &.e-blue {
    border-color: #1423DC;

    .case-study-logo {
      width: 12rem;
    }
    .case-study-img {
      width: 37rem;
      height: auto;
      margin-left: 4rem;
      margin-right: 0;
    }
  }

  &.e-charte {
    border-color: #2382D2;

    .case-study-img {
      margin-left: 6rem;
    }
    .case-study-logo {
      width: 10rem;
    }
  }

  &.e-pedia {
    border-color: #AF2891;

    .case-study-img {
      margin-left: 5rem;
    }
    .case-study-logo {
      width: 12rem;
    }
  }

  p {
    margin: 0;
    font-size: 1.6rem;

    @media (min-width: $md) {
      //font-size: 2.1rem;
    }

    img {
      position: relative;
      top:5px;
      margin-left: 7px;
    }
  }
}

//Case-study.html
.case-study-container {
  .case-study {
    margin: 3rem 0;
  }
}

.cs {
  line-height: 1.5;
  @media (min-width: $md) {
    line-height: 1.7;
  }

  .job {
    margin-bottom: 0;
  }
  .dates {
    margin-top: 0;
    font-size: 1.6rem;
    color: grey;
  }

  &-title {
    font-size: 2.6rem;
    font-family: $font-playfair;
    margin-bottom: 0;
    margin-top: 2rem;
    line-height: 1.2;
    @media (min-width: $md) {
      font-size: 3.2rem;
    }
  }

  &-subtitle {
    font-size: 2.2rem;
    margin-bottom: 0;
    font-family: $font-montserrat;
    line-height: 1.2;

    @media (min-width: $md) {
      line-height: 1.5;
    }
  }

  p {
    font-size: 1.6rem;
    letter-spacing: -0.3px;
    margin: 2rem 0;
    @media (min-width: $md) {
      font-size: 1.8rem;
    }
  }

  ul {
    margin: 0;
    li {
      font-size: 1.6rem;
      line-height: 1.6;
      @media (min-width: $md) {
        font-size: 1.8rem;
        line-height: 2;
      }
    }
  }
}

.cs-title + .cs-subtitle {
  margin-top: 1rem;
}

.cs-title + p,
.cs-title + h4 {
  margin-top: 0.5rem;
}

.cs-subtitle + p,
.cs-subtitle + h4 {
  margin-top: 0;
}

.cs-nrj-img1 {
  max-width: 20rem;
  margin-top: 2rem;
  @media (min-width: $md) {
    margin-top: -15rem;
    max-width: 100%;
  }
}

//Pour la lecture
.cs {
  .container {
    max-width: 80rem;
  }
}