$bg-color: #B7DAD4;
$bg-color-light: #416F67;
$body-color: #202022;

$font-montserrat:  'Montserrat', sans-serif;
$font-playfair:  'Playfair Display', serif;

$font-size-base:              1.6rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * 0.875 !default;

$sm: 54rem;
$md: 54rem; //72
$lg: 96rem;
$xl: 128rem;

$line-height-base:            1.5 !default;
$line-height-small:          $line-height-base * 0.8 !default;

*,
*::before,
*::after {
  box-sizing: border-box; // 1
}

html {
  font-family: sans-serif; // 2
  line-height: 1.15; // 3
  text-size-adjust: 100%; // 4
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  color: $body-color;
  font-family: $font-montserrat;
  line-height: $line-height-base;
  margin: 0; // 1
  font-size: $font-size-base;
  text-align: left; // 3
}

@import "flex-grid-lite";

@import "utils";
@import "case-study";
@import "title";
@import "home";
@import "menu";
@import "intro";
@import "footer";
@import "about";