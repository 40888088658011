.header {
  @media (min-width: $md) {
    //margin: 0 5rem;
    //padding: 0 2rem;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-logo {
    display: none;
    font-family: $font-playfair;
    text-decoration: none;
    color: $body-color;
    font-weight: bold;
    font-size: 3rem;

    &:hover {
      color: $bg-color;
    }

    @media (min-width: $md) {
      display: block;
    }
  }

  &-menu {
    width: 100%;
    padding: 2rem 0;

    @media (min-width: $md) {
      width: auto;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: space-between;

      @media (min-width: $md) {
        justify-content: flex-start;

        li {
          margin-left: 5rem;
        }
      }

      a, .text {
        text-decoration: none;
        color: $body-color;
        position: relative;
        display: inline-block;


        &:hover {
          color: $bg-color-light;
        }

        &.active {
          font-weight: bold;
          display: inline-block;

          &::before {
            display: inline-block;
            content: "";
            background-color: $bg-color;
            position: absolute;
            width: 100%;
            height: 1rem;
            z-index: -1;
            bottom: -0.2rem;
          }
        }
      }
    }
  }
}