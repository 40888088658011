.footer {
  margin-top: 4rem;
  text-align: center;
  font-family: $font-playfair;
  color: white;
  padding: 4rem 0;
  letter-spacing: 0.5px;

  h4, ul {
    margin: 0;
    list-style-type: none;
    padding: 0;
  }

  h4 {
    font-size: 2rem;
    font-weight: normal;

    @media (min-width: $md) {
      font-size: 3.8rem;
    }
  }

  a:hover {
    opacity: 0.75;
  }

  ul {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 2rem;

    li {
      margin: 0 2rem;

      img {
        max-width: 4rem;
      }
    }
  }
}