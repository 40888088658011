.about {
  @media (min-width: $md) {
    display: flex;
  }

  &-text {
    margin-top: 1rem;
    font-size: 1.6rem;
    letter-spacing: -0.5px;
    height: 100%;

    @media (min-width: $md) {
      font-size: 2rem;
      margin-right: 5rem;
    }
  }

  img {
    margin-right: 4rem;
    margin-top: -5rem;

    @media (min-width: $md) {
      width: 35rem;
    }
  }
}

.nng {
  align-items: center;
  font-family: $font-playfair;
  margin-bottom: 3rem;
  margin-top: 3rem;

  @media (min-width: $md) {
    display: flex;
  }

  p {
    margin: 0;
    padding-right: 2rem;
    font-size: 1.8rem;

    @media (min-width: $md) {
      font-size: 2.1rem;
    }
  }

  img {
    width: 23rem;
    margin-right: 3rem;
  }
}

.skills {
  &-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    h3 {
      margin-top: 0;
      margin-bottom: 0;

      @media (min-width: $md) {
        margin-bottom: 0;
      }
    }

    ul {
      padding: 0;
      list-style-position: inside;
      font-size: 1.6rem;
      margin-top: 1rem;

      @media (min-width: $md) {
        font-size: 1.8rem;
      }
    }
  }
}