.section {
  &-intro {
    background-color: $bg-color;
    padding: 3rem 0;
    max-width: 90rem;
    margin: auto;

    .subheading {
      display: flex;
      align-items: center;
      margin-top: 1.5rem;

      h2 {
        font-size: 1.6rem;
        font-family: $font-montserrat;
        font-style: normal;

        @media (min-width: $md) {
          font-size: 2.1rem;
        }
      }

      &-logo {
        width: 5rem;
        margin-right: 1rem;
      }
      &-logo-large {
        width: 13rem;
        margin-right: 1rem;
      }
    }

    &.mini {
      padding: 2rem 0;

      @media (min-width: $md) {
        padding: 3rem 0;
      }

      &.about {
        padding-top: 4rem;
        padding-bottom: 8rem;

        .container {
          margin: 0 2rem;

          @media (min-width: $md) {
            margin: 0;
          }
        }
      }

      h1 {
        font-size: 2.4rem;

        @media (min-width: $md) {
          font-size: 3.6rem;
        }
      }
    }

    @media (min-width: $lg) {
      max-width: 120rem;
    }

    span {
      font-family: $font-playfair;
      font-style: italic;
      position: relative;
      margin-left: 3.5rem;

      i {
        font-style: normal;
      }

      &::before {
        content: "";
        position: absolute;
        width: 2.5rem;
        height: 0.2rem;
        left: -3rem;
        top:1rem;
        background-color: $body-color;
      }

      @media (min-width: $md) {
        font-size: 2.6rem;
        margin-left: 4.5rem;

        &::before {
          width: 3.5rem;
          left: -4rem;
          top:1.6rem;
        }
      }
    }

    h1 {
      font-family: $font-playfair;
      font-weight: bold;
      margin: 0;
      font-size: 3.6rem;
      line-height: 1.2;

      @media (min-width: $md) {
        font-size: 5.6rem;

      }
    }

    h2 {
      font-family: $font-playfair;
      font-weight: normal;
      margin: 0;
      font-size: 2.8rem;
      line-height: 1.2;

      @media (min-width: $md) {
        font-size: 3.6rem;
        line-height: 1.1;
      }
    }

    p {
      font-size: 1.6rem;
      @media (min-width: $md) {
        line-height: 1.3;
        font-size: 2rem;
        margin-top: 1.6rem;
        //max-width: 78%;
      }
    }
  }
}