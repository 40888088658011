.main-title {
  margin: 2rem 0;

  &.sm-p {
    @media (min-width: $md) {
      margin-top: 3rem;
      margin-bottom: 2rem;
    }
  }

  @media (min-width: $md) {
    margin-top: 3rem;
    margin-bottom: 2.5rem;
  }

  h2 {
    font-family: $font-playfair;
    font-size: 2.4rem;
    font-weight: normal;
    margin-bottom: 1rem;
    line-height: 1.2;

    @media (min-width: $md) {
      font-size: 3.8rem;
      line-height: 1.5;
      margin: 0;
    }
  }

  p {
    font-family: $font-montserrat;
    font-size: 1.4rem;
    margin: 0;
    font-weight: 100;
    line-height: 1.3;

    @media (min-width: $md) {
      font-size: 2rem;
      line-height: 1.3;
    }
  }
}